<template>
  <div class="content-wrapper overflow-visible">
    <patient-info :patient-data="patientData" :loading="loading.patientInfo" :use-history-data="true" :reg-id="$route.params.pageSlug" />

    <div class="content">
      <validation-observer ref="VFormCPPT">
        <b-form @submit.prevent="doSubmit" class="card">
          <div class="card-header">
            <h6 class="card-title font-weight-semibold">Tambah CPPT SOAP</h6>
          </div>
          
          <div class="card-body">
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label class="" for="">Tanggal</label>
                  <div class="input-group">
                    <datepicker v-model="row.arancppa_tanggal" input-class="form-control transparent"
                      placeholder="Pilih Tanggal" class="my-datepicker"
                      calendar-class="my-datepicker_calendar">
                    </datepicker>
                    <div class="input-group-append calendar-group">
                      <span class="input-group-text" id="basic-addon2"><i
                          class="icon-calendar"></i></span>
                    </div>
                  </div>
                  <VValidate name="Tanggal" v-model="row.arancppa_tanggal"
                    :rules="toValidate(mrValidation.arancppa_tanggal)" />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label class="" for="">Jam</label>
                  <div class="input-group">
                    <div class="input-group-prepend"><span class="input-group-text"><i class="icon-alarm"></i></span></div>
                    <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="row.arancppa_jam">
                    </vue-timepicker>
                  </div>
                  <VValidate name="Jam" v-model="row.arancppa_jam"
                    :rules="toValidate(mrValidation.arancppa_jam)" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group"><label for="Subjective">Subjective <strong class="text-danger">*</strong></label>
                <b-form-textarea v-model="row.arancppa_subjektif" :formatter="normalText" name="Subjective" id="Subjective" rows="3" class="form-control"></b-form-textarea>
                <VValidate name="Subjective" v-model="row.arancppa_subjektif"
                    :rules="toValidate(mrValidation.arancppa_subjektif)" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group"><label for="objective">Objective <strong class="text-danger">*</strong></label>
                <b-form-textarea v-model="row.arancppa_objektif" :formatter="normalText" name="Objective" id="Objective" rows="3" class="form-control"></b-form-textarea>
                <VValidate name="Objective" v-model="row.arancppa_objektif"
                    :rules="toValidate(mrValidation.arancppa_objektif)" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group"><label for="assessment">Assessment <strong class="text-danger">*</strong></label>
                <b-form-textarea v-model="row.arancppa_assesmen" :formatter="normalText" name="Assessment" id="Assessment" rows="3" class="form-control"></b-form-textarea>
                <VValidate name="Assessment" v-model="row.arancppa_assesmen"
                    :rules="toValidate(mrValidation.arancppa_assesmen)" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group"><label for="planning">Planning <strong class="text-danger">*</strong></label>
                <b-form-textarea v-model="row.arancppa_planning" :formatter="normalText" name="Planning" id="Planning" rows="3" class="form-control"></b-form-textarea>
                <VValidate name="Planning" v-model="row.arancppa_planning"
                    :rules="toValidate(mrValidation.arancppa_planning)" />
                </div>
              </div>
            </div>
          </div>

          <div class="card-footer">
            <div class="text-right">
              <button type="button" @click="back()" class="btn btn-light mr-3">Back</button>
              <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
            </div>
          </div>
        </b-form>
      </validation-observer>
    </div>
  </div>
</template>


<script>
import GlobalVue from '@/libs/Global.vue'
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'

import Datepicker from 'vuejs-datepicker'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import PatientInfo from '@/components/Ranap/PatientInfo.vue'

export default {
  extends: GlobalVue,
  components:{ Datepicker,VueTimepicker,PatientInfo },
  data(){
    return {
      rowReg: {},
      activeAnat: 0,
      
      patientData: {},
      loading: {
          patientInfo: false,
      }
    }
  },
  computed: {
    
  },
  methods: {
    back() {
      this.$router.back()
    },

    apiGet(params = {}, page = 1){
      if(!this.pageSlug){
          this.$router.push({name : 'Dashboard'}).catch(()=>{})
      }

      if(this.pageSlug || this.$route.name=='Dashboard') this.loadingOverlay = true
      this.data.data = false
      let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
      let url = this.$route.params.cpptNo ? this.modulePage+'/'+this.$route.params.pageSlug+'/'+this.$route.params.cpptNo : this.modulePage+'/'+this.$route.params.pageSlug
      
      Gen.apiRest(
          "/get/"+url, 
          {
          params: Object.assign({page: page}, paramsQuery, params.query||{})
          }
      ).then(res=>{
          this.loadingOverlay = false
          _.forEach(res.data, (v,k)=>{
              this.$set(this, k, v)
          })
          if(!this.isFound){
              this.$router.push({name : 'Dashboard'}).catch(()=>{})
          }
      }).catch(()=>{
          this.loadingOverlay = false
      })

      // master
      if(this.$route.params.cpptNo){
        Gen.apiRest(
            "/get/"+url+'?master=1', 
            {
            params: Object.assign({page: page}, paramsQuery, params.query||{})
            }
        ).then(res=>{
            _.forEach(res.data, (v,k)=>{
                this.$set(this, k, v)
            })
        })
      }    
    },
    
    getPatientInfo() {
        this.$set(this.loading, 'patientInfo', true)
        Gen.apiRest(
        "/do/" + 'RanapPasienPerawatan', {
            data: {
              type: "get-data-pasien",
              id_registrasi: this.$route.params.pageSlug
            }
        },
        "POST"
        ).then(resp => {
        this.$set(this, 'patientData', resp.data.data)

        this.$set(this.loading, 'patientInfo', false)
        })
    },

    getConfigDynamic(master,value){
        let text = ''
        if(value){
            let index = (master||[]).findIndex(x => x.value == value)
            if(index !== -1){
                text = master[index]['text']
            }
        }
        return text
    },
    
    autoSave: _.debounce(function (data) {
      data.type = 'auto-save'

      if(!data.isEdit){
        Gen.apiRest(
            "/do/"+this.modulePage,
            {data:data}, 
            "POST"
        )
      }
    },1000),

  
    toValidate(val) {
      return {
        ...val
      }
    },
    
    doSubmit(){

      if(this.row.arancp_is_active == 'N'){
        return this.$swal({
            icon: 'error',
            title: 'Data Telah tidak Aktif',
            text: 'Silakan Kembali Ke Dashboard dan cari no Registrasi yang sama'
        }).then(result => {
            if (result.value) {
              setTimeout(()=>{
                  let inv = []
                  let el = document.querySelectorAll('.label_error')
                  for(let i = 0; i < (el||[]).length; i++){
                      if(el[i].style.display !== 'none'){
                          inv.push(el[i].id)
                      }
                  }
                  if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
              },500)
            }
        })
      }

      this.$refs['VFormCPPT'].validate().then(success => {
        if (!success) {
          return this.$swal({
              icon: 'error',
              title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
          }).then(result => {
              if (result.value) {
                  setTimeout(() => {
                      let inv = []
                      let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                      console.log(el)
                      for (let i = 0; i < (el || []).length; i++) {
                          if (el[i].style.display !== 'none') {
                          inv.push(el[i].id)
                          }
                      }
                                  
                      if (inv.length) document.getElementById(inv[0]).scrollIntoView({
                          behavior: 'smooth',
                          block: 'center'
                      })
                  }, 500)
              }
          })
        }
        
        
        if (success) {
          this.$swal({
            icon: 'warning',
            title: 'Apakah Anda Yakin akan menyimpan data ini?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if (result.value) {
              let data = this.row
              data.type = 'submit-data'

              this.loadingOverlay = true
              Gen.apiRest(
              "/do/" + this.modulePage, {
                  data: data
              },
              "POST"
              ).then(res => {
                  this.loadingOverlay = false
                  
                  let resp = res.data
                  resp.statusType = 200
                  this.$swal({
                      title: resp.message,
                      icon: resp.status,
                      confirmButtonText: 'Ok',
                      allowOutsideClick: false,
                      allowEscapeKey: false
                  }).then(result => {
                      this.$router.push({ name: 'RanapCPPTList' }).catch(()=>{})   
                  })
              })
            }
          })
        }
        
      })
    },
  },
  mounted() {
    this.apiGet()

    this.getPatientInfo()        
  },
  watch: {
    row: {
      handler(v) {
        this.autoSave(v)
      },
      deep: true
    },
  }
}
</script>